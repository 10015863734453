import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import catalog from './_catalog.js';

const catalogItem = (item, cat) => {
  let description = item.description;
  let page = item.page;
  if (cat === 'storage') {
    description = catalog.content.storage_description.replace('##storage##', item.label);
    page = '/storage/' + item.key;
  } else if (cat === 'storage-backup') {
    description = catalog.content.storage_backup_description.replace('##storage##', item.label);
    page = '/storage-backup/' + item.key;
  } else {
    description = item.description;
  }
  return (
    <Link
      to={page}
      className="p-6 bg-white rounded-xl text-dark hover:text-dark hover:shadow-lg transition-all cursor-pointer flex justify-between flex-col border border-indigo-100">
      <div className="flex  items-center gap-3 mb-4 ">
        <Ui.Image filename={item.image} className="w-7"/>
        <div className="font-medium text-base leading-tight whitespace-nowrap overflow-ellipsis overflow-hidden">{ item.label }</div>
      </div>
      <div className="flex flex-1 flex-row text-base items-start justify-between">
        <p className="text-sm text-gray-500">
          { description}
        </p>
        <i className="ml-2 text-sm fa fa-angle-right text-gray-300"></i>
      </div>
    </Link>
  );
}

const buildCatalog = (category = null) => {
  let items = catalog.items;
  let catalogElt = [];
  let categories = catalog.categories;
  if (category !== null) {
    categories = catalog.categories.filter(obj => {
      return obj.key === category;
    });
  }

  categories.map((cat) => {
    let items = catalog.items.filter(obj => {
      return cat.items.includes(obj.key);
    });

    catalogElt.push(
      <>
        <h3 className="text-2xl font-bold">{ cat.label }</h3>
        <div className="grid grid-cols-2 md:grid-cols-3 my-6 gap-6 mb-12">
          { items.map(item => catalogItem(item, cat.key)) }
        </div>
      </>
    );
  });

  return catalogElt;
};

const CatalogPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <Layout.Layout>
      <Layout.SEO refKey="catalog"/>
      <Layout.HeroEmpty/>

      <Ui.Section color="">
        <Ui.Container>
          <Ui.Row>
            <div className="col-md-12 text-center">
              <h1 className="font-heading text-4xl md:text-5xl leading-tight font-black">
                Integrations
              </h1>
              <p>Integrate your backups with best-of-class solutions that run the web</p>
            </div>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>
      <Ui.Section color="alt">
        <Ui.Container>
          <div className="grid grid-cols-12 py-16">
            <aside className="hidden md:block md:col-span-3">
              <div className="mt-9 font-bold"></div>
              <nav className="space-y-3 my-3 text-base">
                <span onClick={() => setSelectedCategory(null)} className={classnames({'flex cursor-pointer': true, 'text-dark': selectedCategory !== null, 'text-primary font-bold': selectedCategory === null})} >All</span>
                { catalog.categories.map(cat => (
                  <span onClick={() => setSelectedCategory(cat.key)} className={classnames({'flex cursor-pointer': true, 'text-dark': selectedCategory !== cat.key, 'text-primary  font-bold': selectedCategory === cat.key})}>{cat.label}</span>
                ))}
              </nav>
            </aside>
            <div className="col-span-12 md:col-span-9">
              {buildCatalog(selectedCategory)}
            </div>
          </div>
        </Ui.Container>
      </Ui.Section>
    </Layout.Layout>
  )
}

export default CatalogPage;
