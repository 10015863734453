import CatalogPage from "./catalog/_catalogPage";

export default CatalogPage

export const frontmatter = {
  pageMetaData: {
    refKey: "catalog",
    title: "Integration Catalog - SimpleBackups",
    description: "SimpleBackups integrates with most cloud providers, IaaS, DBaaS and tools you use. Connect and enjoy your backups on auto-pilot.",
  }
}
